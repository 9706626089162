//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  // import smartMap from 'smart-event-map';
  import remoteLoad from '@/common/remoteLoad'

  export default {
    name: "orderMap",
    data() {
      return {
        value: '',
        chooseValue: {},
        mapStyle: {},
        placeSearch: null,
        dragStatus: false,
        AMapUI: null,
        AMap: null,
        lat: null,
        lng: null,
        first: true,
        poiPicker:{},
        isXianXia: false //是否是线下餐厅
      };
    },
    // watch: {
    //   'value': function () {
    //     this.poiPicker.suggest(this.value);
    //     console.log(1)
    //   }
    // },
    components: {},
    created() {
        // var hsCities = JSON.parse(localStorage.getItem('hsCities'))
        this.isXianXia = this.$route.params.isXianXia;
    },
    mounted: async function () {
      this.value = this.$route.query.name;
      let _this = this;
      window.onresize = function () {
        _this.resizeMap();
      }
      // 已载入高德地图API，则直接初始化地图
      if (window.AMap && window.AMapUI) {
        this.initMap()
        // 未载入高德地图API，则先载入API再初始化
      } else {
        // 2bb900ee621fcd705cf4ca3d526754f5
        await remoteLoad('https://webapi.amap.com/maps?v=1.4.6&key=7c7b892fe70103211de5180dc1715010')
        await remoteLoad('https://webapi.amap.com/ui/1.0/main.js?v=1.0.11')
        this.initMap()
      }
    },
    methods: {
      setAddress(data){
          // this.storage.set('el', this.chooseValue.location.lng);
          // this.storage.set('nl', this.chooseValue.location.lat);
        console.log(data)
        var address = {}
        var position = data.position
        if(position) {
            address.lat = position.lat
            address.lng = position.lng
        }
        var regeocode = data.regeocode
        if(regeocode && regeocode.addressComponent) {
           var addressComponent =  regeocode.addressComponent
           address.city = addressComponent.city || addressComponent.province
        }          
          // this.storage.set('el', address.lng) 
          // this.storage.set('nl',address.lat)
          // this.storage.set('gpscity', address.city);
          // this.chooseValue = address
              
       },
      saveMap() {
        // console.log(this.map.getInfoWindow().map.Ma.Om.z.title)
        // let node = document.getElementsByClassName('BMap_bubble_title');
        if (!this.chooseValue.name) {
          this.$toast("请选择地图标注位置！");
          return
        }
        // let content = document.getElementsByClassName('BMap_bubble_title')[0].childNodes[0].title;
        // console.log(content)gpscity
        console.log(this.chooseValue, "this.chooseValue")
        var chooseValue = this.chooseValue;
        if(this.isXianXia) {
          var shopData = {
            isExtra: false,
            fullName: chooseValue.name,
            shopAddress: chooseValue.address,
            province: chooseValue.province,
            city: chooseValue.city || chooseValue._city || chooseValue.province,
            cityRegion: chooseValue.cityRegion             
          }
          this.closeWebview(shopData)
        }else {
          var location = this.chooseValue.location;
          this.iStorage.set('el', location.lng);
          this.iStorage.set('nl', location.lat);
          this.iStorage.set('gpscity', chooseValue.city || chooseValue._city || chooseValue.province);
          this.iStorage.set('address', chooseValue.address);
          this.$router.back();
        }
      },
    closeWebview(shopData) {
      this.iJsBridge.setResult({ postData: shopData });
      this.iJsBridge.close();
    },      
      back() {
        this.$router.back();
      },
      resizeMap() {
        let wiHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.mapStyle = {
          'height': wiHeight-44 + 'px'
        }
      },
      // 搜索
      handleSearch() {
        if (this.value) {
          this.placeSearch.search(this.value)
        }
      },
      // 实例化地图
      initMap() {
        // var _this =this
        this.resizeMap();
        // 加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
        let AMapUI = this.AMapUI = window.AMapUI
        let AMap = this.AMap = window.AMap
        let mapConfig = {
          resizeEnable: true,
          zoom: 16
        }
        // this.lat = this.storage.get('el')
        // this.lng = this.storage.get('nl')
        // // if (this.lat && this.lng) {
        // //   mapConfig.center = [this.lng, this.lat]
        // // }
        let map = new AMap.Map('js-container', mapConfig)    
    map.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,//是否使用高精度定位，默认:true
            timeout: 0,          //超过1秒后停止定位，默认：无穷大
            buttonOffset: new AMap.Pixel(15, 150),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
            zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            buttonPosition:'RB',
            maximumAge: 10000,
        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, 'complete', onComplete);//返回定位信息
        AMap.event.addListener(geolocation, 'error', onError);      //返回定位出错信息
    });
    //解析定位结果
    function onComplete(data) {
        // _this.setAddress(data)
    }
    //解析定位错误信息
    function onError(data) {
        // console.log(data)
    }               
    AMapUI.loadUI(['misc/PositionPicker', 'misc/PoiPicker'], (PositionPicker, PoiPicker) => {
          // 加载地图搜索插件
          // AMap.service('AMap.PlaceSearch', () => {
          //   this.placeSearch = new AMap.PlaceSearch({
          //     pageSize: 5,
          //     pageIndex: 1,
          //     citylimit: true,
          //     map: map,
          //     panel: 'js-result'
          //   })
          // })
          // let mapConfig = {
          //   zoom: 16
          // }
          // if (this.lat && this.lng) {
          //   mapConfig.center = [this.lng, this.lat]
          // }
          //  let map = new AMap.Map('js-container', mapConfig) 
        //  //获取城市  
        //   this.getCity(map)  

          // 创建poiPicker
          let poiPicker = new PoiPicker({
            input: 'pickerInput'
          });
          this.poiPickerReady(poiPicker, map);
          // 启用工具条         
          AMap.plugin(['AMap.ToolBar'], function () {
            map.addControl(new AMap.ToolBar({
              position: 'RB'
            }))
          })
          // 创建地图拖拽
          let positionPicker = new PositionPicker({
            mode: 'dragMap', // 设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
            map: map // 依赖地图对象
          })
          // 拖拽完成发送自定义 drag 事件
          positionPicker.on('success', positionResult => {
            // 过滤掉初始化地图后的第一次默认拖放
            if (!this.dragStatus) {
              this.dragStatus = true
            } else {
              console.log(positionResult)
              // this.setAddress(positionResult)
              this.chooseValue.location = positionResult.position;
              this.chooseValue.address = positionResult.address;
              this.chooseValue.province = positionResult.regeocode.addressComponent.province;
              this.chooseValue.city = positionResult.regeocode.addressComponent.city;
              this.chooseValue.cityRegion = positionResult.regeocode.addressComponent.district;
              this.$emit('drag', positionResult)
            }
          })
          // 启动拖放
          positionPicker.start();
        })
      },
      poiPickerReady(poiPicker, map) {
        window.poiPicker = poiPicker;
        this.poiPicker = poiPicker;

        var marker = new AMap.Marker();

        var infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -20)
        });
        let _this = this

        //选取了某个POI
        poiPicker.on('poiPicked', function (poiResult) { 
          console.log(poiResult)       
          // _this.chooseValue = poiResult.item;cityname
          _this.chooseValue = {
            name: poiResult.item.name,
            _city: poiResult.item.cityname
          }
          _this.value = poiResult.item.name;
          // + poiResult.item.address
          // console.log(_this.chooseValue)
          // var source = poiResult.source,
          var poi = poiResult.item,
            info = {
              // source: source,
              // id: poi.id,
              name: poi.name,
              location: poi.location.toString(),
              address: poi.address
            };

          marker.setMap(map);
          infoWindow.setMap(map);

          marker.setPosition(poi.location);
          infoWindow.setPosition(poi.location);

          infoWindow.setContent('位置信息: <p>名称:' + info.name + '</p>' + '<p>地址:' + info.address + '</p>');
          infoWindow.open(map, marker.getPosition());

          map.setCenter(marker.getPosition());
        });

        poiPicker.onCityReady(function () {
          console.log(_this.value)
          poiPicker.suggest(_this.value);
        });
      },
      getCity(map) {
          map.getCity((data) => {
            if (data['city'] && typeof data['city'] === 'string') {
                this.storage.set('gpscity', data['city']);
            }
          
          });
      }
    },
  };
